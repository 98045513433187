import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  RolePermisionsByRoleListService,
  RolePermisionsService,
  UpdateRolePermissionsService,
} from "../services/RoleEditService";
import { EditRole } from "../modules/EditRole";
import Spinner from "components/MDSpinner";
import { RoleByIDService } from "layouts/role/services/RoleService";
import { RoleUpdateService } from "layouts/role/services/RoleService";
import MDAlert from "components/Alert";

export default function RoleEditController() {
  const { id } = useParams(); // Obtener el ID del rol desde la URL

  const [permissions, setPermissions] = useState([]); // Para los permisos generales
  const [permissionsByRol, setPermissionsByRol] = useState([]); // Para los permisos específicos del rol
  const [loading, setLoading] = useState(true); // Estado de carga
  const [roleData, setRoleData] = useState({});
  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");

  const requestRolePermitList = async () => {
    try {
      // Obtener los datos por rol
      const data = await RoleByIDService(id);
      //debugger;
      setRoleData(data.payload);

      // Obtener los permisos por rol
      const rolePermissionsResult = await RolePermisionsByRoleListService(id);
      //debugger;
      if (rolePermissionsResult.success) {
        setPermissionsByRol(rolePermissionsResult.payload);
      }

      // Obtener todos los permisos
      const permissionsResult = await RolePermisionsService();
      if (permissionsResult.success) {
        setPermissions(permissionsResult.payload);
      } else {
        handleAlert("fail", rolePermissionsResult.message);
        console.error("Error al obtener los permisos por rol:", rolePermissionsResult);
      }
    } catch (error) {
      handleAlert("fail", error.message);
      console.error("Error al obtener permisos:", error);
    } finally {
      setLoading(false); // Marcar como cargado
    }
  };

  useEffect(() => {
    requestRolePermitList(); // Hacer la petición al cargar el componente
  }, [id]); // Dependiendo de `id`, se hace la petición nuevamente

  const handleUpdatePermissions = async (updatedPermissions) => {
    setLoading(true);
    try {
      // Llamamos al servicio para actualizar los permisos en el backend
      const response = await UpdateRolePermissionsService(id, updatedPermissions);

      if (response.success) {
        setPermissionsByRol(updatedPermissions);
        // Podrías hacer algo aquí después de una actualización exitosa, como redirigir o mostrar un mensaje
      } else {
        handleAlert("fail", response.message);
        console.error("Error al actualizar los permisos:", response.message);
      }

      // Si necesitas actualizar los datos del rol en el backend
      const responseRole = await RoleUpdateService(id, roleData);
      if (responseRole.success) {
        handleAlert("success", "Rol actualizado correctamente.");
      } else {
        handleAlert("fail", responseRole.message);
        console.error("Error al actualizar el rol:", responseRole.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al actualizar los permisos");
      console.error("Error al actualizar los permisos:", error);
    } finally {
      setLoading(false); // Terminar el estado de carga
    }
  };

  const handleFieldChange = (field, value) => {
    setRoleData((prev) => ({ ...prev, [field]: value }));
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  // Verificar si los datos han llegado
  if (loading) return <Spinner loading={loading} />;

  return (
    <>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      <EditRole
        allPermissions={permissions}
        permissionsByRolList={permissionsByRol}
        handleUpdatePermissions={handleUpdatePermissions}
        roleData={roleData}
        handleFieldChange={handleFieldChange}
      />
    </>
  );
}
