/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import DataSourceController from "./controllers/DataSourceController";
import { DataSourceFileController } from "./controllers/DataSourceFileController";
import { useLocation, Link } from "react-router-dom";
import Breadcrumbs from "examples/Breadcrumbs";
import { useEffect, useState } from "react";
import CarnetsPerFileController from "layouts/carnets_per_file/controllers/CarnetsPerFileController";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Data
export default function SourcesFile() {
  const location = useLocation();
  const [nameSource, setNameSource] = useState("");
  const [valueTab, setValueTab] = useState(0); // Estado para controlar las pestañas

  // Manejar cambio de pestañas
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    // Recuperar datos de sessionStorage
    const storedNameSource = sessionStorage.getItem("nameSource");

    if (storedNameSource) setNameSource(storedNameSource);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h3" color="white">
                  Listado de archivos de la fuente: {nameSource && nameSource}
                </MDTypography>
              </MDBox>

              {/* Pestañas */}
              <Box sx={{ width: "100%", mt: 3 }}>
                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="simple tabs example">
                  <Tab value={0} label="Listado de carnets" wrapped />
                  <Tab value={1} label="Historial de fuentes de datos" />
                </Tabs>
              </Box>

              {/* Contenido basado en la pestaña seleccionada */}

              {valueTab === 0 && (
                <MDBox pt={3}>
                  <CarnetsPerFileController />
                </MDBox>
              )}
              {valueTab === 1 && (
                <MDBox pt={3}>
                  <DataSourceFileController />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
