// Mensajes de éxito
export const SUCCESS_MESSAGES = {
  DOWNLOAD_COMPLETE_CARD: "Se descargó correctamente el carnet",
  DOWNLOAD_COMPLETE_SOURCE: "Se descargó correctamente el archivo",
  UPDATE_SUCCESS: "Actualizado correctamente",
  CREATE_SUCCESS: "Creado correctamente",
  DELETE_SUCCESS: "Eliminado correctamente",
  SEND_EMAIL_SUCCESS: "Correo enviado correctamente",
  VALIDITY_UPDATED: "Vigencia actualizada correctamente",
  STATUS_UPDATED: "Estado actualizado correctamente",
};

// Mensajes de error
export const ERROR_MESSAGES = {
  DOWNLOAD_FAILED_CARD: "No se pudo descargar el carnet",
  DOWNLOAD_FAILED_SOURCE: "Fallo al descargar el archivo",
  UPDATE_FAILED: "Error al actualizar",
  CREATE_FAILED: "Error al crear",
  DELETE_FAILED: "Error al eliminar",
  SEND_EMAIL_FAILED: "Error al enviar correo",
  SERVER_ERROR: "Error al consultar al servidor",
  MISSING_ID: "No se encontró el identificador del carnet",
  TOO_MANY_REQUESTS:
    "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.",
};

// Mensajes informativos
export const INFO_MESSAGES = {
  NO_DATA: "No hay datos",
  LOADING: "Cargando...",
  CONFIRMATION_REQUIRED: "Por favor confirme esta acción",
};
