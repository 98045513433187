/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@iudigital.edu.co                              ║
╚════════════════════════════════════════════════════════════╝
*/

/**
 * Descarga un archivo de forma segura, generando una URL temporal o utilizando FileSaver
 * cuando está disponible. Maneja diferentes escenarios de descarga y errores comunes.
 *
 * @param {Blob} file - El blob del archivo que se va a descargar
 * @param {string} fileName - Nombre predeterminado para el archivo si no se puede extraer del Content-Disposition
 * @param {string} contentDisposition - Cabecera HTTP Content-Disposition que puede contener el nombre del archivo
 * @param {Function} onSuccess - Callback que se ejecuta cuando la descarga es exitosa, recibe el mensaje de éxito
 * @param {Function} onError - Callback que se ejecuta cuando ocurre un error, recibe el mensaje de error
 * @param {string} messageSuccess - Mensaje personalizado que se pasa al callback de éxito
 * @param {string} messageFail - Mensaje personalizado que se pasa al callback de error si no hay un mensaje específico
 *
 * @example
 * // Ejemplo básico de uso
 * downloadFile(
 *   blobFile,
 *   "documento.pdf",
 *   response.headers.get("Content-Disposition"),
 *   (msg) => showSuccessToast(msg),
 *   (err) => showErrorToast(err),
 *   "Archivo descargado con éxito",
 *   "Error al descargar el archivo"
 * );
 */
export const downloadFile = (
  file,
  fileName,
  contentDisposition,
  onSuccess,
  onError,
  messageSuccess,
  messageFail
) => {
  let url;
  let downloadLink;
  try {
    if (!(file instanceof Blob)) {
      throw new Error("El archivo proporcionado no es un Blob válido");
    }

    let safeFileName = fileName || "archivo_descargado";
    if (contentDisposition) {
      // Extraer el nombre del archivo de la cabecera Content-Disposition
      // La expresión regular busca un patrón "filename=" seguido opcionalmente por comillas
      // y captura todos los caracteres hasta encontrar una comilla, punto y coma o salto de línea
      const match = /filename="?([^";\n]+)"?/.exec(contentDisposition);
      // Sanitizar el nombre del archivo eliminando caracteres problemáticos
      // Solo se preservan: letras, números, guion bajo (_), punto (.) y guion (-)
      // Esto elimina caracteres que podrían causar problemas en sistemas de archivos como:
      // - Espacios en blanco y tabulaciones
      // - Caracteres de control
      // - Caracteres especiales no permitidos en sistemas de archivos (/, \, :, *, ?, ", <, >, |)
      // - Caracteres no ASCII que podrían no ser manejados correctamente
      safeFileName = match && match[1] ? match[1].replace(/[^\w.-]/g, "") : safeFileName;
    }

    if (typeof saveAs === "function") {
      // FileSaver maneja internamente la creación de URL y limpieza
      saveAs(file, safeFileName);
    } else {
      url = window.URL.createObjectURL(file);

      downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = safeFileName;
      downloadLink.rel = "noopener noreferrer";
      downloadLink.style.display = "none";

      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

    if (typeof onSuccess === "function") {
      onSuccess(messageSuccess);
    }
  } catch (error) {
    if (typeof onError === "function") {
      onError(error?.message || messageFail);
    }
  } finally {
    if (url) {
      try {
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(url);
      } catch (e) {
        console.error("Error al liberar recursos:", e.message || messageFail);
      }
    }
  }
};
