/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import fetchWrapper from "fetchWrapper";
import React from "react";
import { date } from "yup";

const url = process.env.REACT_APP_API_URL;

//consultar el endpoint que me retornara el listado de carnets o error si ocurre y retornar esos datos
const getCarnetUser = async (pageIndex) => {
  try {
    const response = await fetchWrapper(url + `/cards/get_more_info?page=${pageIndex}`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara la informacion de un usuario carnetizado por la id enviada del usuario o error si ocurre y retornar esos datos
const getUserId = async (idUser) => {
  try {
    const response = await fetchWrapper(url + `/card_users/card_user/${idUser}`);
    const { id, name, lastname, number_id, email, created_at } = await response.json();
    return { id, name, lastname, number_id, email, created_at };
  } catch (error) {}
};

//consultar el endpoint que me retornara el listado de tipos de carnet o error si ocurre y retornar esos datos
const getTypeCarnets = async () => {
  try {
    const response = await fetchWrapper(url + `/card_types/card_types_without_pagination`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { payload } = result;
    return payload;
  } catch (error) {}
};

//consultar el endpoint que me retornara si el cambio de la vigencia de carnets fue exitoso o error si ocurre y retornar esos datos
const updatedCardValidity = async (editValidityCard) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/cards/validity`,
      JSON.stringify(editValidityCard),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara el archivo con la cantidad de carnets descargados
const downloadCardAccumulated = async (ids) => {
  try {
    const response = await fetchWrapper(
      url + `/cards/card_file_accumulated`,
      JSON.stringify(ids),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const result = await response.json();
        const { success, message } = result;
        if (!success) {
          const status = false;
          return { status, message };
        }
      } else {
        const blob = await response.blob();
        const message = "Se descargó correctamente el archivo";
        const status = true;
        return { status, message, blob };
      }
    } else {
      const message = "Falló al descargar el archivo: ";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = error.message;
    const status = false;
    return { status, message };
  }
};

const updatedStatusCarnet = async (id, statusCarnet) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/cards/edit/${id}`,
      JSON.stringify(statusCarnet),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

const getFileCardById = async (id) => {
  try {
    // Realizamos la petición sin modificar el stream
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/cards/card_file/${id}`);

    // Verifica si la respuesta indica demasiadas peticiones
    if (response.status === 429) {
      return {
        status: false,
        message:
          "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.",
      };
    }

    // Verifica redirecciones o rutas de error
    if (response.redirected || response.url.includes("error_pkpass")) {
      const queryParams = new URLSearchParams(response.url.split("?")[1]);
      const errorMessage =
        queryParams.get("message") ||
        "Error al crear el archivo .pkpass con los datos proporcionados.";
      return { status: false, message: errorMessage };
    }

    // Si la respuesta no es exitosa, se lee el error (consumiendo el stream) y se retorna
    if (!response.ok) {
      // Aquí no clonamos: si la respuesta es un error, consumimos el body y salimos.
      const errorResult = await response.json();
      return {
        status: false,
        message: errorResult.message || "Error del servidor al realizar la solicitud",
      };
    }

    // Evitamos llamar a clone() ya que podría estar afectando en algunos casos.
    const responseClone = response.clone();
    const originalBlob = await responseClone.blob();
    // Crear un nuevo blob con el tipo MIME específico
    const fileBlob = new Blob([originalBlob], { type: "application/vnd.apple.pkpass" });
    return {
      status: true,
      file: fileBlob,
      contentDisposition: response.headers.get("Content-Disposition"),
    };
  } catch (error) {
    return {
      status: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
};

export {
  getCarnetUser,
  getUserId,
  getTypeCarnets,
  updatedCardValidity,
  updatedStatusCarnet,
  downloadCardAccumulated,
  getFileCardById,
};
