import React from "react";
import fetchWrapper from "../../../fetchWrapper";

//import { useAuth } from "../../../context/index";
//const { token, login, logout } = useAuth();
const getCardPerId = async (id, qr) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/cards/verify_card/${id}/${qr}`
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    // const { users } = await response.json();
    const result = await response.json();
    const { success } = result;
    if (success === true) {
      const { payload } = result;
      return { payload };
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

const updatedJoinCarnet = async (id, statusCarnet) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/cards/edit/${id}`,
      JSON.stringify(statusCarnet),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      return result;
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export { getCardPerId, updatedJoinCarnet };
