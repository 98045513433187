/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/

import fetchWrapper from "fetchWrapper";

//consultar el endpoint que me retornara el listado de tipos de fuentes o error si ocurre y retornar esos datos(no se esta usando)
const getDataSources = async (pageIndex) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/data_sources/?page=${pageIndex}`
    );
    // Verificar si el estado de la respuesta es 429 (Too Many Requests)
    if (response.status === 429) {
      // Retornar un mensaje personalizado y un estado de error
      const message =
        "Has excedido el límite de solicitudes. Por favor, intenta de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to };
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara el listado de tipos de fuentes o error si ocurre y retornar esos datos
const getSourceTypes = async (pageIndex) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/sources`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const { payload } = result;
      return { payload };
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara si se creo correctamente la fuente de datos o error si ocurre y retornar esos datos
const createSource = async (data) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/sources/upload_file`,
      data, // Convierte el objeto 'data' a una cadena JSON y envíalo en el cuerpo de la solicitud.
      "POST" // Usa 'PUT' para actualizar un recurso existente.
      //headers: {
      //"Content-Type": "application/json",
      //},
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();

    if (result.success === true) {
      return result;
    } else {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
    // return result;
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara si se creo correctamente la fuente de datos o error si ocurre y retornar esos datos
const createSourceDB = async (data) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/sources/upload_db`,
      JSON.stringify(data), // Convierte el objeto 'data' a una cadena JSON y envíalo en el cuerpo de la solicitud.
      "POST" // Usa 'PUT' para actualizar un recurso existente.
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const { payload } = result;
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//No se esta usando pero se puede usar para actualizar la fuente de datos o error si ocurre y retornar esos datos
const updateSource = async (id, data) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/data/fuente/edit?id=${id}`,
      JSON.stringify(data), // Convierte el objeto 'data' a una cadena JSON y envíalo en el cuerpo de la solicitud.
      "PUT" // Usa 'PUT' para actualizar un recurso existente.
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const { fuentes } = await response.json();
  } catch (error) {}
};

export { getDataSources, createSource, updateSource, getSourceTypes, createSourceDB };
