import { useEffect, useState, useRef } from "react";
import {
  UserCreateService,
  UserListService,
  UserUpdateService,
  UserDeleteService,
  UserByIDService,
} from "../services/UserService";
import UserList from "../modules/UserList";
import { UserCreate } from "../modules/UserCreate";
import { UserEdit } from "../modules/UserEdit";
import { UserDelete } from "../modules/UserDelete";
import { RoleListService } from "layouts/role/services/RoleService";
import FilterController from "components/filter/controller/FilterController";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import usePermission from "hooks/usePermission";

export default function UserController() {
  // Common Controllers
  const [isDisabled, setIsDisabled] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);
  const [recoveredData, setRecoveredData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [newData, setNewData] = useState({});
  const [buildData, setBuildData] = useState({});
  const [loading, setLoading] = useState(false);
  const header = [{}];
  const filterControllerRef = useRef();
  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");

  const dataFilter = useState([
    {
      field: "NOMBRE",
      value: "name",
    },
    {
      field: "CORREO ELECTRÓNICO",
      value: "email",
    },
  ]);
  const dataTable = "users"; //Tabla indicada

  // Field Check
  const [handleRoleList, setRoleList] = useState();

  const requestRoleList = async () => {
    const result = await RoleListService();
    if (result && result.payload) {
      const { payload } = result;
      const rolePermitsArray = Object.entries(payload);

      setRoleList(rolePermitsArray);
    }
  };

  useEffect(() => {
    requestRoleList();
  }, []);

  const getFieldValueNew = (fieldName) => {
    return newData[fieldName];
  };

  const getFieldValue = (fieldName) => {
    return editedData[fieldName];
  };

  const handleFieldChangeNew = (fieldName, value) => {
    setNewData({
      ...newData,
      [fieldName]: value,
    });
    handleChangeValid(fieldName, value);
  };

  const handleFieldChange = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
    handleChangeValid(fieldName, value);
  };

  // ByID Controllers

  const requestUserByID = async (idUser) => {
    try {
      const user = await UserByIDService(idUser);
      const { payload } = user;
      return { payload };
    } catch (error) {}
  };

  const requestListRoles = async () => {
    try {
      const listRoles = await RoleListService(1);
      const { data } = listRoles;
      return { data };
    } catch (error) {}
  };

  // List Controllers

  const [handleUserList, setUserList] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [paginaSize, setPaginaSize] = useState(10);

  const requestUserList = async (pageIndex) => {
    const result = await UserListService(1);
    if (result) {
      const { payload } = result;
      await setUserList(payload.data);
      await setTotalPages(payload.last_page);
      await setTotalData(payload.total);
      await setEntriesStart(payload.from);
      await setEntriesEnd(payload.to);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    requestUserList(currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1);
  };

  useEffect(() => {
    requestUserList(currentPage);
  }, [currentPage]);

  // List Role Controllers
  /*
  const [handleRoleList, setRoleList] = useState();

  const requestRoleList = async (pageIndex) => {
    const result = await RoleListService(pageIndex);
    if (result && result.payload) {
      const { payload } = result;
      const roleArray = Object.entries(payload.data);
      setRoleList(payload.data);
    }
  };

  useEffect(() => {
    requestRoleList();
  }, []);
 */

  // Create Controllers

  const [openCreate, setOpenCreate] = useState(false);

  const handleUserCreate = (event) => {
    event.preventDefault();
    setIsDisabled(false);
    resetFieldValid();
    const data = {
      name: "",
      lastname: "",
      email: "",
      status: "",
      role_id: "",
    };
    setDefaultData(data);
    setNewData(data);
    setOpenCreate(true);
  };

  const submitUserCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        setLoading(false);
        return;
      }
    }
    const convertedObject = convertValuesToString(newData);
    const result = await UserCreateService(newData);
    if (result.status !== false) {
      // console.log("hola");
      //requestUserList(1);
      callHandleClickSubmit();
      closeUserCreate();
      handleAlert("success", "Usuario creado correctamente");
    } else {
      handleAlert("fail", result.message);
    }
    setLoading(false);
  };

  const closeUserCreate = () => setOpenCreate(false);

  // Edit Controllers

  const [openEdit, setOpenEdit] = useState(false);
  const [listRoles, setLisRoles] = useState([]);

  const handleUserEdit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setIsDisabled(event.currentTarget.dataset.id === "view" ? true : false);
    const user = await requestUserByID(event.currentTarget.dataset.id);
    const listRoles = await requestListRoles();
    setRecoveredData(user.payload);
    setEditedData(user.payload);
    setLisRoles(listRoles);
    setOpenEdit(true);
    setLoading(false);
  };

  const submitUserEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        setLoading(false);
        return;
      }
    }
    const convertedObject = convertValuesToString(editedData);
    const result = await UserUpdateService(editedData.id, editedData);

    if (result.status !== false) {
      //requestUserList(1);
      callHandleClickSubmit();
      closeUserEdit();
      handleAlert("success", "Usuario actualizado correctamente");
    } else {
      handleAlert("fail", result.message);
    }
    setLoading(false);
  };

  const closeUserEdit = () => setOpenEdit(false);

  // Delete Controllers

  const [openDelete, setOpenDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState({});

  const requestUserDelete = async (id) => {
    try {
      const result = await UserDeleteService(id);

      if (result.status !== false) {
        // const { data } = result;
        handleAlert("success", "El usuario ahora esta inactivo");
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al eliminar el usuario");
    }
  };

  const handleUserDelete = (event) => {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.id,
      name: dataset.name,
      lastname: dataset.lastname,
      email: dataset.email,
      status: dataset.status,
      role_id: dataset.role_id,
    };
    setInfoDelete(data);
    setOpenDelete(true);
  };

  const submitUserDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    const convertedObject = convertValuesToString(infoDelete.id);
    await requestUserDelete(infoDelete.id, infoDelete.id);
    //requestUserList(1);
    callHandleClickSubmit();
    closeUserDelete();
    setLoading(false);
  };

  const closeUserDelete = () => setOpenDelete(false);

  // Test Keys

  useEffect(() => {
    const hasFormChanged = Object.keys(editedData).some(
      (fieldName) => editedData[fieldName] !== recoveredData[fieldName]
    );

    const isAnyFieldEmpty = Object.values(editedData).some((value) => value === "");

    setIsFormModified(!isAnyFieldEmpty && hasFormChanged);
  }, [editedData]);

  useEffect(() => {
    const requiredFields = ["name", "description"];
    const someFieldIsEmpty = requiredFields.some((fieldName) => !newData[fieldName]);
    setIsFormModified(someFieldIsEmpty);
  }, [newData]);

  // Stringify

  function convertValuesToString(objeto) {
    const convertedObject = {};

    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        convertedObject[clave] = String(objeto[clave]);
      }
    }

    return convertedObject;
  }

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    name: true,
    lastname: true,
    email: true,
    role_id: true,
    status: true,
  });

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      name: true,
      lastname: true,
      email: true,
      role_id: true,
      status: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    name: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/, // Solo permite letras, espacios, la letra ñ y letras con tildes
    lastname: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/, // Solo permite letras, espacios, la letra ñ y letras con tildes
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Verifica el formato de correo electrónico
    role_id: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    status: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    const isFieldValid = validateField(value, validationPatterns[fieldName]);

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>
      {usePermission("User.byFilter") && (
        <FilterController
          dataTable={dataTable}
          data={handleUserList}
          dataFilter={dataFilter}
          setListData={setUserList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
          requestData={requestUserList}
          // setError={setError}
          setTotalData={setTotalData}
          setEntriesEnd={setEntriesEnd}
          setEntriesStart={setEntriesStart}
          setLoading={setLoading}
          ref={filterControllerRef}
        ></FilterController>
      )}
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      <UserList
        handleUserList={handleUserList}
        handleUserCreate={handleUserCreate}
        handleUserEdit={handleUserEdit}
        handleUserDelete={handleUserDelete}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />

      <UserCreate
        open={openCreate}
        handleClose={closeUserCreate}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueNew}
        handleFieldChange={handleFieldChangeNew}
        handleSubmit={submitUserCreate}
        isFieldValid={isFieldValid}
      />

      <UserEdit
        open={openEdit}
        handleClose={closeUserEdit}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValue}
        handleFieldChange={handleFieldChange}
        handleSubmit={submitUserEdit}
        listRoles={listRoles}
        isFieldValid={isFieldValid}
      />

      <UserDelete
        openDelete={openDelete}
        closeDelete={closeUserDelete}
        infoDelete={infoDelete}
        submitDelete={submitUserDelete}
      />
    </>
  );
}
