/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  styled,
  Button,
  Card,
  LinearProgress,
  CircularProgress,
  InputLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  FormHelperText,
} from "@mui/material";
import colors from "assets/theme/base/colors";
import closeImg from "assets/images/close_X.svg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import MDAlert from "components/MDAlert";
import React, { useState } from "react";
import DataTable from "examples/Tables/DataTable";
import MDProgress from "components/MDProgress";
import Spinner from "components/MDSpinner";

export function SourceNew({
  open,
  handleClose,
  isFormModified,
  isDisabled,
  isAlert,
  errorAlert,
  spinner,
  getFieldValue,
  sourceTypes,
  carnetTypes,
  handleFieldChange,
  handleSubmit,
  handleEstablishConnection,
  setColumTableNative,
  columTableNative,
  columData,
  statusConexion,
  tableRows,
  loading,
  isFieldValid,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "1100px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    maxHeight: "95vh",
  };

  const modalContentStyle = {
    padding: "20px",
    maxHeight: "80%", // Establece la altura máxima para el contenido del modal
    overflowY: "auto", // Agregar scroll vertical al contenido del modal
  };

  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    align: "center",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "15px",
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const tableColumns = [
    { Header: "BD Nativa", accessor: "staticValue" },
    { Header: "BD Entrante", accessor: "select" },
    // Tus otras columnas aquí...
  ];

  return (
    <>
      {/* <Spinner loading={loading}></Spinner> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            mt={-7}
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            p={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography id="modal-modal-title" component="h2">
              Nueva fuente de datos
            </Typography>
            <Icon
              sx={{
                fontSize: "2em",
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            >
              close
            </Icon>
          </MDBox>
          <Divider variant="middle" />
          <div style={{ maxHeight: "80%", overflowY: "auto" }}>
            <form onSubmit={handleSubmit}>
              <MDBox
                sx={content}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Tipo de fuente
                  </Typography>
                  <FormControl sx={{ minWidth: 80 }} error={!isFieldValid.name}>
                    <Select
                      labelId="labelId-select-tipo-de-fuente"
                      id="select-tipo-de-fuente"
                      value={getFieldValue("name")}
                      onChange={(e) => handleFieldChange("name", e.target.value)}
                      style={{ fontSize: "15px", padding: "10px" }}
                      disabled={isDisabled}
                      displayEmpty
                    >
                      <MenuItem value={""}>Seleccione...</MenuItem>
                      {sourceTypes
                        ? sourceTypes.map((source) => {
                            return (
                              <MenuItem
                                key={source.id}
                                value={source.name}
                                disabled={source.name === "MySQL" || source.name === "PostgreSQL"}
                              >
                                {source.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                    {!isFieldValid.name && (
                      <FormHelperText>Tipo de fuente requerida</FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Nombre de la fuente
                  </Typography>
                  <MDInput
                    disabled={isDisabled}
                    style={{ marginTop: "5px" }}
                    label=""
                    value={getFieldValue("source_name")}
                    error={!isFieldValid.source_name}
                    helperText={!isFieldValid.source_name ? "No puede estar vacio!" : ""}
                    FormHelperTextProps={{
                      className: !isFieldValid.source_name ? "Mui-error" : "",
                    }}
                    onChange={(e) => handleFieldChange("source_name", e.target.value)}
                  />
                </Box>
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Descripción de la fuente
                  </Typography>
                  <MDInput
                    disabled={isDisabled}
                    style={{ marginTop: "5px" }}
                    label=""
                    multiline
                    rows={3}
                    value={getFieldValue("source_description")}
                    error={!isFieldValid.source_description}
                    helperText={!isFieldValid.source_description ? "No puede estar vacio!" : ""}
                    FormHelperTextProps={{
                      className: !isFieldValid.source_description ? "Mui-error" : "",
                    }}
                    onChange={(e) => handleFieldChange("source_description", e.target.value)}
                  />
                </Box>
                {getFieldValue("name") === "CSV" && (
                  <Box sx={{ marginRight: "20px" }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Fuente: Archivo csv
                    </Typography>
                    <>
                      <span>
                        <a
                          href="/assets/Plantilla CSV.csv"
                          download="Plantilla CSV.csv"
                          target="_self"
                          title="Descarga la plantilla de ejemplo"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Descargar plantilla
                        </a>
                      </span>

                      <MDInput
                        disabled={isDisabled}
                        style={{ marginTop: "5px" }}
                        label=""
                        type="file"
                        error={!isFieldValid.CSV}
                        helperText={!isFieldValid.CSV ? "El archivo no es de extension CSV" : ""}
                        FormHelperTextProps={{
                          className: !isFieldValid.CSV ? "Mui-error" : "",
                        }}
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          handleFieldChange("source", selectedFile);
                        }}
                      ></MDInput>
                      <label
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                          display: "inline-block",
                        }}
                        title={getFieldValue("source") ? getFieldValue("source").name : ""}
                      >
                        {getFieldValue("source") ? getFieldValue("source").name : ""}
                      </label>
                    </>
                  </Box>
                )}
                {getFieldValue("name") === "JSON" && (
                  <Box sx={{ marginRight: "20px" }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Fuente: Archivo json
                    </Typography>
                    <>
                      <span>
                        <a
                          href="/assets/Plantilla JSON.json"
                          download="Plantilla JSON.json"
                          target="_self"
                          title="Descarga la plantilla de ejemplo"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Descargar plantilla
                        </a>
                      </span>

                      <MDInput
                        disabled={isDisabled}
                        style={{ marginTop: "5px" }}
                        label=""
                        type="file"
                        error={!isFieldValid.JSON}
                        helperText={!isFieldValid.JSON ? "El archivo no es de extension .json" : ""}
                        FormHelperTextProps={{
                          className: !isFieldValid.JSON ? "Mui-error" : "",
                        }}
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          handleFieldChange("source", selectedFile);
                        }}
                      ></MDInput>
                      <label
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                          display: "inline-block",
                        }}
                        title={getFieldValue("source") ? getFieldValue("source").name : ""}
                      >
                        {getFieldValue("source") ? getFieldValue("source").name : ""}
                      </label>
                    </>
                  </Box>
                )}
                {getFieldValue("name") === "EXCEL" && (
                  <Box sx={{ marginRight: "20px" }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Fuente: Archivo excel
                    </Typography>
                    <>
                      <span>
                        <a
                          href="/assets/Plantilla Excel.xlsx"
                          download="Plantilla Excel.xlsx"
                          target="_self"
                          title="Descarga la plantilla de ejemplo"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Descargar plantilla
                        </a>
                      </span>

                      <MDInput
                        disabled={isDisabled}
                        style={{ marginTop: "5px" }}
                        label=""
                        type="file"
                        error={!isFieldValid.EXCEL}
                        helperText={
                          !isFieldValid.EXCEL ? "El archivo no es de extension .xlsx o .xls" : ""
                        }
                        FormHelperTextProps={{
                          className: !isFieldValid.EXCEL ? "Mui-error" : "",
                        }}
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          handleFieldChange("source", selectedFile);
                        }}
                      ></MDInput>
                      <label
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100px",
                          display: "inline-block",
                        }}
                        title={getFieldValue("source") ? getFieldValue("source").name : ""}
                      >
                        {getFieldValue("source") ? getFieldValue("source").name : ""}
                      </label>
                    </>
                  </Box>
                )}
                {getFieldValue("name") === "MySQL" && (
                  <>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Url de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Url de la base de datos"
                          value={getFieldValue("db_host")}
                          error={!isFieldValid.db_host}
                          helperText={!isFieldValid.db_host ? "Ingresa la URl" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.db_host ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("db_host", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Puerto de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="3306"
                          value={getFieldValue("port")}
                          error={!isFieldValid.port}
                          helperText={!isFieldValid.port ? "Solo números" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.port ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("port", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de usuario
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="root"
                          value={getFieldValue("db_username")}
                          error={!isFieldValid.db_username}
                          helperText={!isFieldValid.db_username ? "No puede estar vacio!" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.db_username ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("db_username", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Contraseña
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="password"
                          placeholder="********"
                          value={getFieldValue("db_password")}
                          onChange={(e) => handleFieldChange("db_password", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("nameDB")}
                          error={!isFieldValid.nameDB}
                          helperText={!isFieldValid.nameDB ? "No puede estar vacio!" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.nameDB ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("nameDB", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de la tabla
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la tabla"
                          value={getFieldValue("nameTable")}
                          error={!isFieldValid.nameTable}
                          helperText={!isFieldValid.nameTable ? "No puede estar vacio!" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.nameTable ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("nameTable", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Establecer conexión
                      </Typography>
                      <>
                        <MDButton onClick={handleEstablishConnection} color="info">
                          Aplicar
                        </MDButton>
                      </>
                    </Box>
                    {statusConexion && <></>}
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Relacione los campos de nuestra base de datos con la elegida:
                      </Typography>
                      <DataTable
                        sx={{ border: "1px solid black" }}
                        table={{
                          columns: tableColumns,
                          rows: tableRows,
                        }}
                        canSearch={false}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                      />
                    </Box>
                  </>
                )}
                {getFieldValue("name") === "PostgreSQL" && (
                  <>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Url de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Url de la base de datos"
                          value={getFieldValue("db_host")}
                          error={!isFieldValid.db_host}
                          helperText={!isFieldValid.db_host ? "Ingresa la URl" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.db_host ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("db_host", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Puerto de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="5032"
                          value={getFieldValue("port")}
                          error={!isFieldValid.port}
                          helperText={!isFieldValid.port ? "Solo números" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.port ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("port", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de usuario
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Postgres"
                          value={getFieldValue("db_username")}
                          error={!isFieldValid.db_username}
                          helperText={!isFieldValid.db_username ? "No puede estar vacio!" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.db_username ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("db_username", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Contraseña
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="password"
                          placeholder="********"
                          value={getFieldValue("db_password")}
                          onChange={(e) => handleFieldChange("db_password", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("nameDB")}
                          error={!isFieldValid.nameDB}
                          helperText={!isFieldValid.nameDB ? "No puede estar vacio!" : ""}
                          FormHelperTextProps={{
                            className: !isFieldValid.nameDB ? "Mui-error" : "",
                          }}
                          onChange={(e) => handleFieldChange("nameDB", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de la tabla
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la tabla"
                          value={getFieldValue("nameTable")}
                          onChange={(e) => handleFieldChange("nameTable", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Establecer conexión
                      </Typography>
                      <>
                        <MDButton onClick={handleEstablishConnection} color="info">
                          Aplicar
                        </MDButton>
                      </>
                    </Box>
                    {statusConexion && <></>}
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Relacione los campos de nuestra base de datos con la elegida:
                      </Typography>
                      <DataTable
                        sx={{ border: "1px solid black" }}
                        table={{
                          columns: tableColumns,
                          rows: tableRows,
                        }}
                        canSearch={false}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                      />
                    </Box>
                  </>
                )}
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Tipo de carnet
                  </Typography>
                  <FormControl sx={{ minWidth: 80 }} error={!isFieldValid.perfil}>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={getFieldValue("perfil")}
                      onChange={(e) => handleFieldChange("perfil", e.target.value)}
                      style={{ fontSize: "15px", padding: "10px" }}
                      disabled={isDisabled}
                      displayEmpty
                    >
                      <MenuItem value={""}>Seleccione...</MenuItem>
                      {carnetTypes
                        ? carnetTypes.map((carnet) => {
                            return (
                              <MenuItem key={carnet.id} value={carnet.name}>
                                {carnet.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                    {!isFieldValid.perfil && (
                      <FormHelperText>Tipo de carnet requerido</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </MDBox>
            </form>
          </div>
          <Divider variant="middle" />

          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            m={-0.5}
            mb={-2}
          >
            <MDButton color="error" onClick={handleClose}>
              Cancelar
            </MDButton>
            <MDButton
              onClick={handleSubmit}
              type="submit"
              disabled={isDisabled || isFormModified}
              color="success"
            >
              Guardar
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
    </>
  );
}

SourceNew.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isDisabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFormModified: PropTypes.bool,
};
