/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import fetchWrapper from "fetchWrapper";
import React from "react";

const getDataSourceFile = async (id, pageIndex) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/data_sources_file/data_sources_file_per_data_source/${id}?page=${pageIndex}`
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

const getFileDataSource = async (id) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/data_sources_file/file_per_id/${id}`
    );

    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    if (!response.ok) {
      //const result = await response.json();
      // Verificar si la solicitud fue exitosa
      // La solicitud falló
      const result = await response.json();
      if (result.success === false) {
        const message = result.message || "Error del servidor al realizar la solicitud";
        const status = false;
        return { status, message };
        //throw new Error(`Error del servidor al realizar la solicitud`);
      } else {
        throw new Error("Error del servidor al realizar la solicitud");
      }
    }

    // Convertir respuesta a Blob
    const fileBlob = await response.blob();
    return {
      status: true,
      file: fileBlob,
      contentDisposition: response.headers.get("Content-Disposition"),
    };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const getFileReportDataSource = async (id) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/data_sources_file/report_error/${id}`
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    //const result = await response.json();
    // Verificar si la solicitud fue exitosa
    if (!response.ok) {
      const result = await response.json();
      if (result.success === false) {
        const message = result.message || "Error del servidor al realizar la solicitud";
        const status = false;
        return { status, message };
        //throw new Error(`Error del servidor al realizar la solicitud`);
      } else {
        throw new Error("Error del servidor al realizar la solicitud");
      }
    }

    // Convertir respuesta a Blob
    const fileBlob = await response.blob();
    return {
      status: true,
      file: fileBlob,
      contentDisposition: response.headers.get("Content-Disposition"),
    };
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

// Solicitar la lista delos estados de los carnets
const getStatusTypes = async (pageIndex) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/status/?page=${pageIndex}`
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();

    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

// llamar API para agregar un carnet a un usuario
const addCarnet = async (data) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/cards/create`,
      JSON.stringify(data),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      return result;
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara el listado de tipos de fuentes o error si ocurre y retornar esos datos
//consultar el endpoint que me retornara el listado de tipos de fuentes o error si ocurre y retornar esos datos
const getSourceTypes = async (pageIndex) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/sources`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const { payload } = result;
      return { payload };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara el listado de tipos de fuentes o error si ocurre y retornar esos datos
const getDataSourceId = async (id) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/data_sources/data_source/${id}`
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const { payload } = result;
      return { payload };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara si se creo correctamente la fuente de datos o error si ocurre y retornar esos datos
const createSourceFile = async (data) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/data_sources_file/upload_file`,
      data, // Convierte el objeto 'data' a una cadena JSON y envíalo en el cuerpo de la solicitud.
      "POST" // Usa 'PUT' para actualizar un recurso existente.
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    return result;
  } catch (error) {}
};

export {
  getDataSourceFile,
  getStatusTypes,
  addCarnet,
  getFileDataSource,
  getSourceTypes,
  getDataSourceId,
  createSourceFile,
  getFileReportDataSource,
};
