import fetchWrapper from "fetchWrapper";

export const RolePermisionsByRoleListService = async (id) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/permissions/role/${id}`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const payload = await response.json();
    return payload;
  } catch (error) {}
};

export const RolePermisionsService = async () => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/permissions`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const payload = await response.json();
    return payload;
  } catch (error) {}
};

export const UpdateRolePermissionsService = async (roleId, updatedPermissions) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/permissions/role/update/${roleId}`,
      JSON.stringify({
        permissions: updatedPermissions, // Enviamos los permisos seleccionados
      }),
      "PUT"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }

    const payload = await response.json();

    // Verificamos la respuesta de la API
    if (response.ok) {
      return { success: true, payload };
    } else {
      return { success: false, message: payload.message || "Error desconocido" };
    }
  } catch (error) {
    console.error("Error en la actualización de permisos:", error);
    return { success: false, message: error.message };
  }
};
