/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import DataTable from "examples/Tables/DataTable";
import viewSource from "assets/images/ver_fuente.svg";
import editSource from "assets/images/edit_fuente.svg";
import deleteSource from "assets/images/delete_fuente.svg";
import closeImg from "assets/images/close_X.svg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import MDInput from "components/MDInput";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import {
  createSource,
  getSourceTypes,
  getDataSources,
  updateSource,
  createSourceDB,
} from "../services/dataSourceService";
import ListDataSource from "../modules/ListDataSource";
import { SourceDelete } from "../modules/SourceDelete";
import { SourceNew } from "../modules/SourceNew";
import { SourceEdit } from "../modules/SourceEdit";
import { getCardTypesWithoutPagination } from "layouts/card_type/services/cardTypeService";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import { useMaterialUIController } from "../../../context/index";
import { DataSourceFileController } from "layouts/sources_data_file/controllers/DataSourceFileController";
import FilterController from "components/filter/controller/FilterController";
import usePermission from "hooks/usePermission";

export default function DataSourceController() {
  const [controller, dispatch] = useMaterialUIController();
  const [openEdit, setOpenEdit] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [sourceTypes, setSourceTypes] = useState([]);
  const [carnetTypes, setCarnetTypes] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isAlertSuccess, setIsAlertSuccess] = useState(false);
  const [infoSuccess, setInfoSuccess] = useState();
  const [spinner, setSpinner] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [recoveredData, setRecoveredData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [newData, setNewData] = useState({});
  const [newDataDB, setNewDataDB] = useState({});
  const [infoDelete, setInfoDelete] = useState({});
  const [sourceList, setSourceList] = useState();
  const [defaultData, setDefaultData] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const filterControllerRef = useRef();
  const [error, setError] = useState();

  //Constantes de filtro
  const dataFilter = useState([
    {
      field: "NOMBRE",
      value: "source_name",
    },
    {
      field: "TIPO DE FUENTE",
      value: "source_type_name",
    },
    {
      field: "RESPONSABLE",
      value: "user_email",
    },
    {
      field: "FECHA DE CREACIÓN",
      value: "created_at",
    },
  ]);
  const dataTable = "data_sources"; //Tabla indicada
  //Esperar respuesta de la función getSources y validar respuesta para listar las fuentes de datos o mostrar error
  const requestDataSources = async (pageIndex) => {
    try {
      const result = await getDataSources(pageIndex);
      if (result.status !== false) {
        const { data, last_page, total, from, to } = result;
        //if (data.length !== 0) {
        //}
        await setDataSources(data);
        await setTotalPages(last_page);
        await setTotalData(total);
        await setEntriesStart(from);
        await setEntriesEnd(to);
        await setLoading2(false);
        await setLoading(false);
      } else {
        await setLoading2(false);
        await setLoading(false);
        handleAlert("fail", result.message);
      }
    } catch (error) {
      await setLoading2(false);
      await setLoading(false);
      handleAlert("fail", error.message);
    }
  };

  // Esperar respuesta de la función getSourceTypes y validar respuesta para listar los tipos de fuentes disponibles o mostrar error
  const requestSourceTypes = async (pageIndex) => {
    const result = await getSourceTypes(pageIndex);

    if (result.status !== false) {
      const { payload } = result;
      await setSourceTypes(payload);
    } else {
      handleAlert("fail", "No se pudieron cargar los tipos de fuentes");
    }
  };

  //Esperar respuesta de la función getCardTypes y validar respuesta para listar los tipos de carnet o mostrar error
  const requestCarnetTypes = async (pageIndex) => {
    const result = await getCardTypesWithoutPagination();

    if (result.status !== false) {
      const { payload } = result;
      await setCarnetTypes(payload);
    } else {
      handleAlert("fail", "No se pudieron cargar los tipos de carnet");
    }
  };

  // Esperar respuesta de la función createSource y validar respuesta para conocer si se inserto correctamente la fuente de datos o mostrar error
  const requestSourceNew = async (data) => {
    try {
      const result = await createSource(data);
      if (result.status !== false) {
        setInfoSuccess(result.payload);
        handleAlert("success", "Se uso correctamente la información de la fuente de datos");
        setLoading2(true);
        // await requestDataSources(1);
        // Función para llamar a handleClickSubmit desde el componente padre y actualizar la tabla con los filtros actuales
        callHandleClickSubmit();
        await setLoading2(false);
        handleCloseNew();
        //const { message, last_page, total, from, to } = result;
      } else {
        if (result.message === "Archivo no encontrado") {
          await setLoading2(false);
          handleAlert("fail", "Archivo no encontrado");
        } else if (result.message === "type_card not found") {
          await setLoading2(false);
          handleAlert("fail", "El tipo de carnet no existe");
        } else {
          await setLoading2(false);
          handleAlert("fail", result.message);
        }
        //setError("Error al consultar al servidor");
      }
    } catch (error) {
      await setLoading2(false);
      handleAlert("fail", error.message || "Fallo al procesar el archivo");
    }
  };

  // Esperar respuesta de la función createSourceDB y validar respuesta para conocer si se inserto correctamente la fuente de datos o mostrar error
  const requestSourceNewConexionDB = async (data) => {
    try {
      const result = await createSourceDB(data);
      if (result.status !== false) {
        setColumData(result.payload);
        handleAlert("success", "Se estableció conexión correctamente con la base de datos");
        //const { message, last_page, total, from, to } = result;
      } else {
        setIsAlert(true);
        if (result.message === "Archivo CSV no encontrado") {
          handleAlert("fail", "Archivo CSV no encontrado");
        } else if (result.message === "type_card not found") {
          handleAlert("fail", "El tipo de carnet no existe");
        } else {
          handleAlert("fail", result.message || "Fallo al procesar la base de datos elegida");
        }
        //setError("Error al consultar al servidor");
      }
    } catch (error) {
      handleAlert("fail", error.message || "Fallo al procesar la base de datos elegida");
    }
  };

  // Realiza una solicitud para crear una nueva fuente de datos de base de datos utilizando la información proporcionada.
  const requestSourceNewDB = async (data) => {
    try {
      const result = await createSourceDB(data);
      if (result.status !== false) {
        handleAlert("success", "Se uso correctamente la información de la base de datos");
        //const { message, last_page, total, from, to } = result;
      } else {
        setIsAlert(true);
        if (result.message === "Archivo CSV no encontrado") {
          handleAlert("fail", "Archivo CSV no encontrado");
        } else if (result.message === "type_card not found") {
          handleAlert("fail", "El tipo de carnet no existe");
        } else {
          handleAlert(
            "fail",
            result.message || "No se pudo usar la información de la base de datos"
          );
        }
        //setError("Error al consultar al servidor");
      }
    } catch (error) {
      handleAlert("fail", error.message || "No se pudo usar la información de la base de datos");
    }
  };

  // Peticion de las fuentes de datos disponibles
  const requestSourceEdit = async (id, data) => {
    try {
      const result = await updateSource(id, data);
      const { fuentes } = result;
    } catch (error) {}
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  //Maneja la paginacion de la tabla
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Abrir modal para editar la fuente de datos
  const handleOpenEdit = (event) => {
    event.preventDefault();
    setOpenEdit(true);
    setIsDisabled(event.currentTarget.dataset.id === "view" ? true : false);
    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.identificador,
      nombre: dataset.fuente,
      url: dataset.url,
      estado: dataset.estado,
      usuarios: dataset.usuarios,
      fecha_de_generacion: dataset.fechac,
      perfil: dataset.perfil,
      programa: dataset.programa,
    };

    setRecoveredData(data);
    setEditedData(data);
  };

  //Abrir modal para hacer carga masiva por fuente de datos
  const handleOpenNew = async (event) => {
    setLoading(true);
    event.preventDefault();
    setIsDisabled(false);
    resetFieldValid();
    //const { dataset } = event.currentTarget;
    const data = {
      name: "",
      source_name: "",
      source_description: "",
      source: "",
      perfil: "",
      created: "",
      expires_in: "",
      username: controller.user_id,
    };
    setDefaultData(data);
    await requestSourceTypes(1);
    await requestCarnetTypes(1);
    setNewData(data);
    setOpenNew(true);
    setLoading(false);
  };

  // Logica para la actualización de archivos de fuentes de datos ----->
  const [openDataSourcesFileBoard, setOpenDataSourcesFileBoard] = useState(false);
  const [idDataSource, setIdDataSource] = useState("");
  const [nameDataSourceFile, setNameDataSourceFile] = useState("");

  //Abre el modal donde se listan los carnets de un usuario en especifico
  const handleOpenDataSourcesFileBoard = (event) => {
    event.preventDefault();
    setOpenDataSourcesFileBoard(true);
    const { dataset } = event.currentTarget;
    setIdDataSource(dataset.identifier);
    setNameDataSourceFile(dataset.name);
  };

  const handleCloseDataSourcesFileController = () => {
    setOpenDataSourcesFileBoard(false);
  };

  // <------
  // Abre un modal de confirmación para eliminar el elemento seleccionado.
  const handleClickDelete = (event) => {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.identificador,
      nombre: dataset.fuente,
      url: dataset.url,
      estado: dataset.estado,
      usuarios: dataset.usuarios,
      fecha_de_generacion: dataset.fechac,
      perfil: dataset.perfil,
      programa: dataset.programa,
    };
    setInfoDelete(data);
    setOpenDelete(true);
  };

  const handleCloseEdit = () => setOpenEdit(false);
  const handleCloseNew = () => setOpenNew(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const header = [{}];

  //Obtiene el valor de un campo específico del conjunto de datos editados.
  const getFieldValue = (fieldName) => {
    return editedData[fieldName];
  };

  // Obtiene el valor de un campo específico del conjunto de datos nuevos.
  const getFieldValueNew = (fieldName) => {
    return newData[fieldName];
  };

  //Actualiza los datos editados con el nuevo valor del campo especificado.
  const handleFieldChange = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
  };

  //Maneja el cambio de valor de un campo en los datos nuevos y actualiza los datos nuevos según el campo y el valor especificados.
  const handleFieldChangeNew = (fieldName, value) => {
    handleChangeValid(fieldName, value);
    if (fieldName === "name" && (value === "MySQL" || value === "PostgreSQL")) {
      newData["port"] = "";
      newData["db_host"] = "";
      newData["nameDB"] = "";
      newData["nameTable"] = "";
      newData["db_username"] = "";
      newData["db_password"] = "";
      newData["colums"] = "";
      setColumData([]);
      setSelectedValues({});
      delete newData["source"];
    } else if (fieldName === "name" && (value !== "PostgreSQL" || value !== "MySQL")) {
      delete newData["port"];
      delete newData["db_host"];
      delete newData["nameDB"];
      delete newData["nameTable"];
      delete newData["db_username"];
      delete newData["db_password"];
      delete newData["colums"];
      newData["source"] = "";
    }
    setNewData({
      ...newData,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    // Comprueba si algún campo ha cambiado desde los valores iniciales
    const hasFormChanged = Object.keys(editedData).some(
      (fieldName) => editedData[fieldName] !== recoveredData[fieldName]
    );

    const isAnyFieldEmpty = Object.values(editedData).some((value) => value === "");

    setIsFormModified(!isAnyFieldEmpty && hasFormChanged);
  }, [editedData]);

  useEffect(() => {
    // Comprueba si algún campo ha cambiado desde los valores iniciales
    //const hasFormChanged = Object.keys(newData).some((fieldName) => newData[fieldName] !== "");
    //const requiredFields = ["name", "source", "perfil", "created"];
    const requiredFields = Object.keys(newData).filter(
      (clave) => clave !== "db_password" && clave !== "created" && clave !== "expires_in"
    );
    const someFieldIsEmpty = requiredFields.some((fieldName) => !newData[fieldName]);
    setIsFormModified(someFieldIsEmpty);
  }, [newData]);

  // useEffect(() => {
  //   requestDataSources(currentPage);
  // }, [currentPage]);

  //Convierte los valores de un objeto en cadenas de texto.
  function convertValuesToString(objeto) {
    const convertedObject = {};

    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        convertedObject[clave] = String(objeto[clave]);
      }
    }

    return convertedObject;
  }

  // Maneja el envío de un formulario de edición.
  const handleSubmitEdit = (e) => {
    e.preventDefault();
    const convertedObject = convertValuesToString(editedData);
    // Realiza la acción de enviar el formulario aquí
    requestSourceEdit(editedData.id, editedData);
  };

  // Maneja el envío de la fuente de datos para ser consumida segun su formato
  const handleSubmitNew = async (e) => {
    e.preventDefault();
    setIsAlert(false);
    setErrorAlert("");
    setLoading(true);
    if (newData.name === "CSV") {
      const formData = new FormData();
      formData.append("name", newData.name);
      formData.append("source_name", newData.source_name);
      formData.append("source_description", newData.source_description);
      formData.append("source", newData.source);
      formData.append("perfil", newData.perfil);
      formData.append("created", newData.created);
      formData.append("expires_in", newData.expires_in);
      formData.append("username", newData.username);
      const file = getFieldValueNew("source").type;

      if (file === "text/csv") {
        // El archivo es un archivo CSV, puedes realizar acciones con él.
        await requestSourceNew(formData);
        setLoading(false);
        //handleCloseNew();
      } else {
        setIsAlert(true);
        setLoading(false);
        handleAlert("fail", "Por favor, selecciona un archivo CSV");
        // El archivo no es de tipo CSV, realiza la lógica de manejo de error.
      }
    } else if (newData.name === "JSON") {
      const formData = new FormData();
      formData.append("name", newData.name);
      formData.append("source_name", newData.source_name);
      formData.append("source_description", newData.source_description);
      formData.append("source", newData.source);
      formData.append("perfil", newData.perfil);
      formData.append("created", newData.created);
      formData.append("expires_in", newData.expires_in);
      formData.append("username", newData.username);
      const file = getFieldValueNew("source").type;

      if (file === "application/json") {
        // El archivo es un archivo CSV, puedes realizar acciones con él.
        let file = getFieldValueNew("source"); // Obteniendo el objeto File
        // let reader = new FileReader();

        // reader.onload = function (event) {
        //   let data = JSON.parse(event.target.result);
        //   let keys = Object.keys(data[0]);
        // };

        // reader.onerror = function (event) {
        //   handleAlert("fail", "Error al leer el archivo JSON");
        //   return;
        // };

        // reader.readAsText(file);

        await requestSourceNew(formData);
        setLoading(false);
        //handleCloseNew();
      } else {
        setIsAlert(true);
        setLoading(false);
        handleAlert("fail", "Por favor, selecciona un archivo JSON");
        // El archivo no es de tipo CSV, realiza la lógica de manejo de error.
      }
    } else if (newData.name === "EXCEL") {
      const formData = new FormData();
      formData.append("name", newData.name);
      formData.append("source_name", newData.source_name);
      formData.append("source_description", newData.source_description);
      formData.append("source", newData.source);
      formData.append("perfil", newData.perfil);
      formData.append("created", newData.created);
      formData.append("expires_in", newData.expires_in);
      formData.append("username", newData.username);
      const file = getFieldValueNew("source").type;

      if (
        file === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file === "application/vnd.ms-excel"
      ) {
        // El archivo es un archivo CSV, puedes realizar acciones con él.
        await requestSourceNew(formData);
        setLoading(false);
        handleCloseNew();
      } else {
        setIsAlert(true);
        setLoading(false);
        handleAlert("fail", "Por favor, selecciona un archivo EXCEL");
        // El archivo no es de tipo CSV, realiza la lógica de manejo de error.
      }
    } else if (newData.name === "MySQL") {
      await requestSourceNewDB(newData);
      setLoading(false);
      handleCloseNew();
    } else if (newData.name === "PostgreSQL") {
      await requestSourceNewDB(newData);
      setLoading(false);
      handleCloseNew();
    }

    //const convertedObject = convertValuesToString(newData);
    // Realiza la acción de enviar el formulario aquí
    //requestSourceNew(newData);
    setLoading(false);
  };

  const handleSubmitDelete = (e) => {
    e.preventDefault();
    handleCloseDelete();
  };

  const handleCloseAlertSuccess = () => {
    setIsAlertSuccess(false);
  };

  // Fuente de datos por base de datos -->
  const [columTableNative, setColumTableNative] = useState("");
  const [columData, setColumData] = useState([]);
  const [statusConexion, setStatusConexion] = useState(false);

  const [selectedValues, setSelectedValues] = useState({});

  const handleChange = (event, index) => {
    setNewData({
      ...newData,
      ["colums"]: { ...selectedValues, [index]: event.target.value },
    });
    setSelectedValues({ ...selectedValues, [index]: event.target.value });
  };

  const staticValues = ["Nombre", "Apellido", "Documento", "Correo"]; // Tus valores estáticos
  //const allOptions = ["Opción 1", "Opción 2", "Opción 3"]; // Tus opciones para el select

  const tableRows = staticValues.map((value) => {
    // Filtrar las opciones que ya han sido seleccionadas en otras filas
    const availableOptions = columData.filter(
      (option) =>
        !Object.values(selectedValues).includes(option) || selectedValues[value] === option
    );

    return {
      staticValue: value,
      select: (
        <Select
          value={selectedValues[value] || ""}
          onChange={(event) => handleChange(event, value)}
          displayEmpty
          required
        >
          <MenuItem value={""}>Seleccione...</MenuItem>
          {availableOptions.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ),
      // Tus otros valores de fila aquí...
    };
  });

  // Obtiene el valor de un campo específico del conjunto de datos de la tabla nativa de la base de datos
  const getFieldValueNewDB = (fieldName) => {
    return columTableNative[fieldName];
  };

  // Actualiza los datos de la tabla nativa de la base de datos con el nuevo valor del campo especificado.
  const handleFieldChangeNewDB = (fieldName, value) => {
    setColumTableNative({
      ...columTableNative,
      [fieldName]: value,
    });
  };

  // Maneja el establecimiento de la conexión con la base de datos.
  const handleEstablishConnection = async () => {
    setLoading(true);
    const conexionDB = {
      port: newData["port"],
      nameDB: newData["nameDB"],
      nameTable: newData["nameTable"],
      db_username: newData["db_username"],
      db_password: newData["db_password"],
      db_host: newData["db_host"],
    };
    // Verificar si el valor de la clave está vacío y eliminarla si es así
    // for (var clave in newData) {
    //   if (newData.hasOwnProperty(clave) && newData[clave] === "") {
    //     delete newData[clave];
    //   }
    // }
    await requestSourceNewConexionDB(newData);
    setLoading(false);
  };

  // Validaciones --->

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    source_name: true,
    source_description: true,
    name: true,
    perfil: true,
    created: true,
    expires_in: true,
    CSV: true,
    EXCEL: true,
    JSON: true,
    db_host: true,
    port: true,
    db_username: true,
    nameDB: true,
    nameTable: true,
  });

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      source_name: true,
      source_description: true,
      name: true,
      perfil: true,
      created: true,
      expires_in: true,
      CSV: true,
      EXCEL: true,
      JSON: true,
      db_host: true,
      port: true,
      db_username: true,
      nameDB: true,
      nameTable: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    name: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    source_name: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    source_description: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    perfil: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    created: /^\d{4}-\d{2}-\d{2}$/, // Formato aaaa-mm-dd
    expires_in: /^\d{4}-\d{2}-\d{2}$/, // Formato aaaa-mm-dd
    CSV: /\.csv$/, // Archivos con extension .csv
    EXCEL: /\.(xlsx|xls)$/, // Archivos con extension .xlsx y xls
    JSON: /\.json$/, // Archivos con extension .json
    db_host: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    port: /^\d*$/, // Verifica que sea un número
    db_username: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    nameDB: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    nameTable: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    // No validar contraseña
    if (fieldName === "db_password") {
      return;
    }
    if (fieldName === "source") {
      fieldName = newData.name; // se reemplaza "source" por el tipo de archivo actual "CSV" || "EXCEL" || "JSON"
      // Verifica si se ha seleccionado algún archivo
      if (!value) {
        setIsFieldValid((prevValidity) => ({
          ...prevValidity,
          [fieldName]: true,
        }));
        return;
      }
      const isFieldValid = validateField(value.name, validationPatterns[fieldName]);
      setIsFieldValid((prevValidity) => ({
        ...prevValidity,
        [fieldName]: isFieldValid,
      }));
      return;
    }

    // Para otros campos, valida el valor ingresado (o seleccionado)
    const isFieldValid = validateField(value.trim(), validationPatterns[fieldName]);

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  // <-----
  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>

      {usePermission("DataSource.byFilter") && (
        <FilterController
          dataTable={dataTable}
          data={dataSources}
          dataFilter={dataFilter}
          setListData={setDataSources}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
          requestData={requestDataSources}
          setError={setError}
          setTotalData={setTotalData}
          setEntriesEnd={setEntriesEnd}
          setEntriesStart={setEntriesStart}
          setLoading={setLoading}
          ref={filterControllerRef}
        ></FilterController>
      )}
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      <SourceNew
        open={openNew}
        handleClose={handleCloseNew}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        isAlert={isAlert}
        errorAlert={errorAlert}
        spinner={spinner}
        getFieldValue={getFieldValueNew}
        sourceTypes={sourceTypes}
        carnetTypes={carnetTypes}
        handleFieldChange={handleFieldChangeNew}
        handleSubmit={handleSubmitNew}
        handleEstablishConnection={handleEstablishConnection}
        columTableNative={columTableNative}
        setColumTableNative={setColumTableNative}
        columData={columData}
        statusConexion={statusConexion}
        tableRows={tableRows}
        loading={loading}
        isFieldValid={isFieldValid}
      />
      {/* <SourceEdit
        open={openEdit}
        handleClose={handleCloseEdit}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValue}
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmitEdit}
      />
      <SourceDelete
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        infoDelete={infoDelete}
        handleSubmitDelete={handleSubmitDelete}
      ></SourceDelete> */}
      <ListDataSource
        handleOpenNew={handleOpenNew}
        sourceList={dataSources}
        handleOpenEdit={handleOpenEdit}
        handleClickDelete={handleClickDelete}
        isAlertSuccess={isAlertSuccess}
        handleCloseAlertSuccess={handleCloseAlertSuccess}
        infoSuccess={infoSuccess}
        handleOpenDataSourcesBoard={handleOpenDataSourcesFileBoard}
        loading2={loading2}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      ></ListDataSource>
      {openDataSourcesFileBoard && (
        <DataSourceFileController
          openBoard={openDataSourcesFileBoard}
          idDataSource={idDataSource}
          name={nameDataSourceFile}
          onClose={handleCloseDataSourcesFileController}
        ></DataSourceFileController>
      )}
    </>
  );
}
