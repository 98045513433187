/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Cristia David Lemus Calderón                               ║
║ cristian.lemus@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

import { useState } from "react";
import VerifyByDocument from "../modules/VerifyByDocument";
import { getAllCardPerDocumentUser } from "../services/VerifyCarnetService";
import MDAlert from "components/Alert";

export default function VerifyCarnetController() {
  const [document, setNewData] = useState({});
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");

  //ejecuta la funcion requestUpdateValidity con la información necesaria y cierra el modal de actualizar vigencia
  const handleSubmitRequest = async (e) => {
    setIsLoading(true);
    try {
      e.preventDefault();
      const result = await getAllCardPerDocumentUser(document.name);
      if (result.status !== false) {
        // console.log("result", result.payload);
        await setData(result.payload);
        handleAlert("success", "Carnets encontrados correctamente");
      } else {
        handleAlert("fail", result.message || "Error al realizar la consulta");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleAlert("fail", error.message || "Error al realizar la consulta");
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setNewData({
      ...document,
      [fieldName]: value,
    });
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  return (
    <>
      <VerifyByDocument
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmitRequest}
        data={data}
        isLoading={isLoading}
      ></VerifyByDocument>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
    </>
  );
}
