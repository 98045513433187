// fetchWrapper.js
const fetchWrapper = async (url, body = {}, method = "GET") => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user_id");

  const headers = {
    Authorization: `Bearer ${token}`,
    user: user,
  };

  // Determinar si se trata de una petición de archivo
  const isFileRequest =
    url.includes("/data_sources_file/upload_file") ||
    url.includes("/sources/upload_file") ||
    url.includes("/cards/card_file/");

  if (!isFileRequest) {
    headers["Content-Type"] = "application/json";
  }

  const config = {
    method,
    headers,
  };

  if (method !== "GET") {
    config.body = body;
  }

  return await fetch(url, config);
};

export default fetchWrapper;
