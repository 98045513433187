import {
  Alert,
  Box,
  Card,
  Divider,
  FormGroup,
  Grid,
  Icon,
  RadioGroup,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

export default function VerifyById({ data, onSwitchActivate }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "450px",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    maxHeight: "90vh",
  };
  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    align: "center",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "15px",
  };

  const today = new Date();
  const createdDate = new Date(data?.created);
  const expiresInDate = new Date(data?.expires_in);

  const isValid = data?.status === "Activo" && createdDate <= today && expiresInDate >= today;

  let errorMessage = "";
  if (data?.status !== "Activo") {
    errorMessage = "El carnet no está habilitado.";
  } else if (createdDate > today) {
    errorMessage = "El evento aún no está disponible.";
  } else if (expiresInDate < today) {
    errorMessage = "El evento ya finalizó.";
  }

  // Estado local para controlar el valor del Switch
  const [isChecked, setIsChecked] = useState(data?.joined === "1");

  // Actualizar el estado local cuando data?.joined cambia
  useEffect(() => {
    setIsChecked(data?.joined === "1");
  }, [data?.joined]);

  const isActive = data?.status === "Activo";
  const shouldShowSwitch =
    data?.card_type?.name === "Invitado" || data?.card_type?.name === "Visitante";
  const isDisabled = !isValid || isChecked;

  // Manejar el cambio de estado del Switch
  const handleSwitchChange = () => {
    // Permitir cambiar el estado solo si el Switch no está bloqueado
    if (!isDisabled) {
      setIsChecked((prevIsChecked) => !prevIsChecked); // Invertir el valor actual
      if (!isChecked) {
        onSwitchActivate(); // Llamar a la función del controlador al activar el Switch
      }
    }
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-track": {
      opacity: "1 !important", // Sobrescribe la opacidad
    },
    "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
      opacity: "1 !important", // Sobrescribe la opacidad para el estado deshabilitado
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: `${green[600]} !important`,
      "&:hover": {
        backgroundColor: `opacity: unset !important;`,
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${green[600]} !important`,
    },
  }));

  return (
    <>
      {/* <Card sx={style}> */}
      <br />
      <Typography
        id="modal-modal-title"
        component="h2"
        style={{ margin: "auto", textAlign: "center", justifyContent: "center" }}
      >
        Detalles del usuario:
        <br />
        {data?.card_user?.name} {data?.card_user?.lastname}
      </Typography>

      {/* <Divider variant="middle" /> */}
      <br />
      {/* <Box display="flex" alignItems="center">
        <Divider
          variant="middle"
          style={{
            flexGrow: 1,
            borderColor: isValid ? "green" : "red",
          }}
        />
        <Box mx={2}>
          {isValid ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <ErrorIcon style={{ color: "red" }} />
          )}
        </Box>
        <Divider
          variant="middle"
          style={{
            flexGrow: 1,
            borderColor: isValid ? "green" : "red",
          }}
        />
      </Box> */}
      <MDBox style={{ margin: "20px" }}>
        {isValid ? (
          // <Alert severity="success"></Alert>
          <></>
        ) : (
          <>
            <Alert severity="warning">{errorMessage}</Alert>
          </>
        )}
        <div>
          {shouldShowSwitch && (
            <>
              <Typography component="div">{isChecked ? "Ingresado" : "No ha ingresado"}</Typography>
              <PinkSwitch checked={isChecked} onChange={handleSwitchChange} disabled={isDisabled} />
            </>
          )}
        </div>
        <div style={{ maxHeight: "80%" }}>
          <MDBox
            sx={content}
            style={{
              justifyContent: "space-around",
            }}
          >
            <Box sx={{ gridColumn: "1 / -1", marginRight: "20px" }}>
              <Typography color={"black"} fontWeight={500}>
                Documento de identidad
              </Typography>
              <MDInput
                disabled={true}
                style={{ marginTop: "5px" }}
                label=""
                value={data?.card_user?.number_id}
              />
            </Box>
            <Box sx={{ gridColumn: "1 / -1" }}>
              <Box>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Vigencias
                </Typography>
              </Box>
            </Box>
            {/* <Box display="flex" alignItems="flex-start"> */}
            <Box sx={{ marginRight: "25px" }}>
              <Typography color={"black"} fontWeight={500}>
                Desde...
              </Typography>
              <MDInput
                disabled={true}
                type="datetime-local"
                style={{ marginTop: "5px" }}
                label=""
                value={data?.created}
              />
            </Box>
            <Box sx={{ marginRight: "25px" }}>
              <Typography color={"black"} fontWeight={500}>
                Hasta...
              </Typography>
              <MDInput
                type="datetime-local"
                disabled={true}
                style={{ marginTop: "5px" }}
                label=""
                value={data?.expires_in}
              />
            </Box>
            {/* </Box> */}
            {/* <Box display="flex" alignItems="flex-start"> */}
            <Box sx={{ marginRight: "25px" }}>
              <Typography color={"black"} fontWeight={500}>
                Estado
              </Typography>
              <MDInput disabled={true} style={{ marginTop: "5px" }} label="" value={data?.status} />
            </Box>
            <Box sx={{ marginRight: "25px" }}>
              <Typography color={"black"} fontWeight={500}>
                Tipo de carnet
              </Typography>
              <MDInput
                disabled={true}
                style={{ marginTop: "5px" }}
                label=""
                value={data?.card_type?.name}
              />
            </Box>
            {/* </Box> */}
            {data?.card_type?.name == "Invitado" && data?.data_source_file && (
              <Box sx={{ marginRight: "25px" }}>
                <Typography color={"black"} fontWeight={500}>
                  Evento
                </Typography>
                <MDInput
                  disabled={true}
                  style={{ marginTop: "5px" }}
                  label=""
                  multiline
                  value={data?.data_source_file?.data_source?.source_description}
                />
                {/* <Typography variant="body1">
                  {data?.data_source_file?.data_source?.source_description}
                </Typography> */}
              </Box>
            )}
          </MDBox>
        </div>
      </MDBox>
      <Divider variant="middle" />
      {/* </Card> */}
    </>
  );
}
